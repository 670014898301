.root {
	&[data-size="md"] > .label {
		padding: 0px 16px !important;
	}
	&[data-size="sm"] > .label {
		padding: 0px 8px !important;
	}
	&[data-variant="outline-white"] > .label {
		color: white;
		border-color: white;
		background: none;
	}
	&[data-variant="outline-white"]:hover > .label {
		color: #80a2ff;
		border-color: #80a2ff;
	}
	.label {
		&[data-checked="true"] {
			background: #0044ff !important;
			border-color: #0044ff;
		}
	}
	.iconWrapper {
		display: none;
	}
}
