@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/variable/pretendardvariable.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://use.typekit.net/ctt0qxg.css");

@font-face {
  font-family: 'Hf Fintech gothic';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicLight.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicLight.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicLight.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Hf Fintech gothic';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicRegular.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicRegular.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicRegular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Hf Fintech gothic';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicSemiBold.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicSemiBold.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicSemiBold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Hf Fintech gothic';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicBold.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicBold.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/HFFintechGothicBold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'SF Pro Display';
  url: ('../assets/font/SF-Pro-Display-Light.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'SF Pro Display';
  url: ('../assets/font/SF-Pro-Display-Light.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Regular.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Medium.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('https://d11l490t345f7g.cloudfront.net/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'SF Pro Display';
  url: ('../assets/font/SF-Pro-Display-Heavy.otf');
  font-weight: 700;
}
@font-face {
  font-family: 'SF Pro Display';
  url: ('../assets/font/SF-Pro-Display-Black.otf');
  font-weight: 800;
}





