.root {
  background-color: var(--notification-color, var(--mantine-primary-color-filled));

  &::before {
    background-color: none;
  }
}

.description,
.title {
  font-size: var(--mantine-font-size-lg) !important;
  color: var(--mantine-color-white)  !important;
}

.title {
  font-weight: 800;
}
.description{
  white-space: pre;
}

.closeButton {
  color: var(--mantine-color-white);
}
.closeButton:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}