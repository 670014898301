a {
  color: inherit;
  text-decoration: inherit;
}

body {
  overscroll-behavior: none;
}

.hideScroll{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.hideScroll::-webkit-scrollbar {
  display: none;
}

.natural-scroll .content {
  height: 100%;
  overflow-y: auto;
}

.fp-overflow{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  flex-direction: column;
  height: auto;
} 
