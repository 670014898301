.list {
	/* position: relative; */
}

.list {
	/* &:before {
		border-bottom-width: 1px !important;
		border-color: var(--mantine-color-gray-7) !important;
	}
	&[data-variant="dark"]{
		border-bottom-width: 1px !important;
		border-color: var(--mantine-color-gray-7) !important;
	} */

	/* dark mode */
	/* &[data-variant="dark"]::before {
    border-style: solid;
		border-width: 0 0 3px 0 !important;
		border-color: var(--mantine-color-gray-4) !important;
    position: absolute;
    content: "";
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
	} */
	&::before{
		top: 0 !important;
		height: 100%;
		opacity: 0;
	}
}

.tab {
	/* border-bottom-width: 3px !important; */
	text-transform: capitalize;
	&[data-size="xl"] {
		padding: var(--mantine-spacing-xl);
	}
	&[data-size="lg"] {
		padding: var(--mantine-spacing-lg);
	}
	&[data-size="md"] {
		padding: var(--mantine-spacing-md);
	}
	&[data-size="sm"] {
		padding: var(--mantine-spacing-sm);
	}
	&[data-size="xs"] {
		span{
			font-size: 13px !important;
		}
		padding: var(--mantine-spacing-xs);
	}
	&[aria-selected='false']{
		opacity: 0.6;
		&:hover {
			opacity: 1;
		}
	}

	&[data-variant="default"] {
		/* color: var(--mantine-color-gray-1); */

		&:hover {
			background: #ffffff0a;
			border-radius: 0;
		}
	}
}
