/* Custom Variant "Underlined" */
.root {
  &[data-variant='dark'] {
    color: var(--mantine-color-gray-1);
  }
}
.input {
  &[data-variant='underlined'] {
    border-bottom: 1px solid;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    border-color: var(--mantine-color-gray-3);
    &:focus {
      border-color: var(--mantine-color-blue-filled);
      border-width: 2px;
    }
    &[data-error]{
      border: none;
      border-bottom: 1px solid;
    }
  }
  &[data-variant='default'] {
    padding-left: var(--mantine-spacing-sm);
    &:focus {
      border-color: var(--mantine-color-blue-filled);
      border-width: 2px;
    }
  }
  /* Dark Mode */
  &[data-variant='dark'] {
    color: var(--mantine-color-gray-1);
    background-color: none;
    border-color: var(--mantine-color-gray-3);
    border-style: solid;
    border-width: 1px;
  }
}

.label {
  font-weight: 600 !important;
  margin-bottom: var(--mantine-spacing-02);
}