.control:first-of-type:before{
  background-color: rgba(0,0,0,0) !important;
}
.control::before{
  background-color: var(--mantine-color-gray-1) !important;
}
.control{
  &[data-active=true]::before{
    background-color: rgba(0,0,0,0) !important;
  }
  &[data-active=true]+::before{
    background-color: rgba(0,0,0,0) !important;
  }
}